import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Alias} from '../types';
import {Badge} from "@mantine/core";

export const aliasesInitialColumnSet = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Alias) => <NavLink to={`/aliases/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Email Address'),
        width: '1fr',
        render: (item: Alias) => `${item.name}@flexi.email`,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Alias) => item.status == 'Enabled' ? <Badge color={'green'}>Enabled</Badge> :
            <Badge color={'red'}>Disabled</Badge>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Alias) => formatTimestamp(item.created as string),
    },
];
