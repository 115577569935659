import PropTypes from 'prop-types';

import {useNavigate} from 'react-router-dom';

import {Image} from '@mantine/core';
import config from "../../config/index";


export const MediumLogo = ({
                               width = '600',
                               height = '200'
                           }: { width?: string, height?: string }) => {
    const navigate = useNavigate();

    return (
        <Image
            src={'1500x500_logo.png'}
            width={width}
            height={height}
            alt={config.appTitle}
            onClick={() => navigate('/')}
        />
    );
};

MediumLogo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};