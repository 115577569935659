import platform from "./platform";
import {stage} from './stage';
import kinesis from './kinesis.js';


const config = {
    platform,
    stage,
    kinesis,
    appTitle: "Flexi eMail",
    appSubTitle: "Flexi eMail",
    appLogoUrl: "https://s3.amazonaws.com/cdn.flexi.network/flexi.domains/images/logo/flexidomains_logo.png"
};

export default config;
