export default {
    roleName: 'TenantAdmin',
    permissionStatements: [
        {
            Effect: 'Allow',
            Action: [
                'ViewDomainsApplication',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ViewDashboard',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'GetTenant',
                'UpdateTenant',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListSubscriptions',
                'GetSubscription',
                'CreateSubscription',
                'UpdateSubscription',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListPayments',
                'GetPayment',
                'CreatePayment',
                'UpdatePayment',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListTenantUsers',
                'GetTenantUser',
                'CreateTenantUser',
                'UpdateTenantUser',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ViewAccount',
                'SignOut',
            ],
            Resource: '*',
        },
    ],
};
