import {Grid,} from '@mantine/core';

import {ProfileWidget} from './dashboard/ProfileWidget.tsx';
import {HealthWidget} from './dashboard/HealthWidget.tsx';
import {SupportWidget} from './dashboard/SupportWidget.tsx';
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {ReactNode} from "react";
import {useAuth} from "../../../hooks/useAuth.ts";

export const PortalDashboard = () => {

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const columns = {
        leftColumn: [],
        rightColumn: []
    } as { leftColumn: ReactNode[], rightColumn: ReactNode[] }

    if (evaluateAction('GetUserProfile')) {
        columns.leftColumn.push(<ProfileWidget/>)
    }


    if (evaluateAction('ListEmailMessages')) {
        columns.rightColumn.push(<HealthWidget/>)
    }

    if (evaluateAction('ListEmailMessages')) {
        columns.rightColumn.push(<SupportWidget/>)
    }


    return (
        <Grid>
            <Grid.Col span={1}/>
            <Grid.Col span={4}>
                {columns.leftColumn.map(item => <>{item}</>)}
            </Grid.Col>

            <Grid.Col span={6}>
                {columns.rightColumn.map(item => <>{item}</>)}
            </Grid.Col>
            <Grid.Col span={1}/>
        </Grid>
    );
};
