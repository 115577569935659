import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {
    Box,
    Button,
} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useMessages} from '../hooks/useMessages';

import {useAccessControl} from '../../../hooks/useAccessControl';
import PropTypes from 'prop-types';
import {AiOutlinePlus} from 'react-icons/ai'
import {useMessagesSelection} from "../hooks/useMessagesSelection.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useMessages();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Message',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <Button
            variant={"filled"}
            disabled={false}
            leftSection={<AiOutlinePlus/>}
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useMessages();

    const {
        selection,
        handleClearSelection,
    } = useMessagesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (evaluateAction('DeleteMessage')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (evaluateAction('CreateMessage')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Messages')}
            tools={useTools()}
        />
    );
};

AddRecordButton.propTypes = {
    buttonText: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};