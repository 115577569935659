import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/AliasesService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Alias} from '../types';

import {recordSelector} from "../selectors";

const entityKey = 'aliases';

interface AliasState {
    byId: { [key: string]: Alias },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as AliasState;


const normalizeRecord = (record: Alias) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchAliases = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        const filter = {}
        return fetchRecords(filter)
            .then((response) => {
                if (response) {
                    return response.map((record: Alias) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchAlias = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveAlias = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Alias) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateAlias = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Alias }) => {

        const modified = {
            id: record.id,
            description: record.description,
            status: record.status,
        }

        return update(id, modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteAlias = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string, {getState}) => {

        const state = getState();
        const {record} = recordSelector(state, id);

        return deleteRecord(id, record.name)
            .then(() => id);
    },
);


export const storeAlias = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Alias) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchAliases, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchAlias);

                                            // SAVE ONE
                                            saveOneCase(builder, saveAlias);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateAlias);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteAlias);
                                        },
                                    });

export default componentsSlice.reducer;
