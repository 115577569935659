import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useMessage} from '../hooks/useMessage';
import {useMessagesSelection} from '../hooks/useMessagesSelection';
import {useEditPageTools} from '../hooks/useEditPageTools';
import {MessageHtml} from "../components/MessageHtml.tsx";

export const EditPage = () => {

    const {id: componentId} = useParams();

    if (typeof componentId === 'undefined') {
        throw new Error('componentId is required parameter');
    }

    const {
        record,
        fetchRecord,
        updateRecord,
    } = useMessage(componentId);

    const {handleClearSelection} = useMessagesSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [componentId]);

    if (!record) {
        return <PlatformLoader message='Loading message...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={record.name ? record.name : record.id}
            pageNavigation={<EditPageNavigation component={record}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={record}
                    />
                    <MessageHtml record={record}/>
                </Stack>
                :
                <RecordForm
                    record={record}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
