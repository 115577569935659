import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import {useAccessControl} from '../../../hooks/useAccessControl';
import {useAliasesSelection} from '../hooks/useAliasesSelection';
import {IAliasTableColumn,} from '../types';
import {useAliases} from "../hooks/useAliases.ts";
import {useAliasesTableColumns} from "../hooks/useAliasesTableColumns.ts";
import {useAliasesSearch} from "../hooks/useAliasesSearch.ts";
import {useAliasesPaging} from "../hooks/useAliasesPaging.ts";
import {useAuth} from "../../../hooks/useAuth.ts";


export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const isAdmin = [role].includes('Admin');

    const {
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
    } = useAliases();

    const {
        columns,
    } = useAliasesTableColumns();

    const {
        keyword,
        handleFilterByKeyword,
    } = useAliasesSearch();

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = useAliasesPaging();


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useAliasesSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IAliasTableColumn[] = [];
        if (evaluateAction('DeleteAlias')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (isAdmin || item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}>n/a</Checkbox></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);

        return cols;


    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                keyword={keyword}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};

RecordsTable.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};