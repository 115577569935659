import PropTypes from 'prop-types';
import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';

import {
    FormikCreatableSelect,
    FormikTextInput
} from '@flexinet/ui-components';


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='from'
                label='From'
                description='Please enter the message'
                data={[]}
                component={FormikCreatableSelect}
                withAsterisk
            />

            <Field
                name='to'
                label='To'
                description='Please enter the message'
                component={FormikCreatableSelect}
                withAsterisk
            />

            <Field
                name='subject'
                label='Subject'
                description='Please enter the subject'
                component={FormikTextInput}
                withAsterisk
            />

        </SimpleGrid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
